import React from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@xFrame4/components/admin/EntityView';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@xFrame4/components/admin/EntityViewHooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import BlogPostCategory from '@business/blog/BlogPostCategory';
import BlogPostCategoryAddEditForm from './BlogPostCategoryAddEditForm';

interface BlogPostCategoryViewProps {}

const BlogPostCategoryView: FunctionComponent<BlogPostCategoryViewProps> = (props) => {
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<BlogPostCategory>(BlogPostCategory);

    const columns = React.useMemo<GridColumns<BlogPostCategory>>(
        () => [
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'name', headerName: t('NAME'), type: 'string', flex: 1 }
        ],
        [t],
    );

    return (
        <>
            {addEditEntity && (
                <BlogPostCategoryAddEditForm
                    entity={addEditEntity}
                    afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
                />
            )}

            <EntityView<BlogPostCategory>
                id="blogpostcategories"
                title={t('BLOG_POST_CATEGORIES')}
                manager={BlogPostCategory.manager}
                baseFilter={{}}
                pageSize={15}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    { field: 'name_Icontains', label: t('NAME'), type: FieldEditorType.Text }
                ]}
                actions={[]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.name + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
};

export default BlogPostCategoryView;