import BlogPostGenerated, { BlogPostGeneratedEntityManager } from './generated/BlogPost.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class BlogPostEntityManager extends BlogPostGeneratedEntityManager<BlogPost>
{
    constructor()
    {
        super({
            createEntity: () => new BlogPost(),
        });
    }
}

export class BlogPost extends BlogPostGenerated
{
    static _manager: BlogPostEntityManager;
    static get manager(): BlogPostEntityManager
    {
        if (!this._manager) this._manager = new BlogPostEntityManager();
        return this._manager;
    }
}

export default BlogPost;