export enum Environment
{
    Test = 'test',
    Development = 'development',
    Production = 'production'
}

interface Config
{
    /** The app version */
    version: string;
    /** The app environment. */
    environment: Environment;
    /** The URL of this UI. */
    appUrl: string;
    /** The GraphQL API URL.  */
    apiUrl: string;
    /** The default revalidation time Next.js UI. */
    nextJsRevalidateTime?: number;
    /** Is the CDN enabled? */
    isCdnEnabled: boolean;
    /** The base URL of the AWS CDN. */
    cdnBaseUrl: string;
    /** The base URL for the /public directory. */
    publicDirBaseUrl: string;
    /** If CDN is enabled, this points to the CDN /public directory, otherwise to the servers's /public directory. */
    defaultPublicDirBaseUrl: string;
    /** The base name for the React Router (if it's used) */
    reactRouterBaseName?: string;
    /** A list of available languages. */
    languages: AppLanguage[]
    /** The default languge of the app. */
    defaultLanguage: AppLanguage;
    /** If true, the search engine crawlers will be prevented from indexing the website. */
    preventSearchCrawler: boolean;
    /** The Google Analytics tracking ID. */
    googleAnalyticsId?: string;
    /** The Google Universal Analytics tracking ID. */
    googleUniversalAnalyticsId?: string;
    /** The Google Ads tracking ID */
    googleAdsId?: string;
    /** The Google Sign in with Google client ID. */
    googleApiClientId?: string;
    /** The Facebook Pixel tracking ID. */
    facebookPixelId?: string;
    /** The Facebook App ID. */
    facebookAppId?: string;
    /** The Twitter Pixel tracking ID. */
    twitterPixelId?: string;
    /** The TikTok Pixel tracking ID. */
    tikTokPixelId?: string;
    /** The Reddit Pixel tracking ID */
    redditPixelId?: string;
    /** The Klaviyo public API key. Rarely used. */
    klaviyoPublicApiKey?: string;
}

let environment: Environment = process.env.NODE_ENV as Environment;
let isProduction = environment == Environment.Production;
let isCdnEnabled = parseInt(process.env['NEXT_PUBLIC_ADMIN_CDN_ENABLED'] as string) == 1;
let languages = [
    { code: 'en', name: 'English', locale: 'en-US' },
    { code: 'de', name: 'Deutsch', locale: 'de-DE' },
    { code: 'hu', name: 'Magyar', locale: 'hu-HU' },
    { code: 'ro', name: 'Română', locale: 'ro-RO' },
];

let config: Config =
{
    version: process.env['NEXT_PUBLIC_ADMIN_VERSION'] as string,
    environment: environment,
    appUrl: process.env['NEXT_PUBLIC_ADMIN_APP_URL'] as string,
    apiUrl: process.env['NEXT_PUBLIC_ADMIN_API_URL'] as string,
    nextJsRevalidateTime: 10,
    isCdnEnabled: isCdnEnabled,
    cdnBaseUrl: process.env['NEXT_PUBLIC_ADMIN_CDN_BASE_URL'] as string,
    publicDirBaseUrl: process.env['NEXT_PUBLIC_ADMIN_APP_URL'] as string,
    defaultPublicDirBaseUrl: (isProduction && isCdnEnabled ? process.env['NEXT_PUBLIC_ADMIN_CDN_BASE_URL'] : process.env['NEXT_PUBLIC_ADMIN_APP_URL']) as string,
    reactRouterBaseName: process.env['NEXT_PUBLIC_ADMIN_REACT_ROUTER_BASENAME'] as string,
    languages: languages,
    defaultLanguage: languages.find(l => l.code == 'en')!,
    preventSearchCrawler: parseInt(process.env['NEXT_PUBLIC_ADMIN_PREVENT_SEARCH_CRAWLER'] ?? '1') == 1,
    googleAnalyticsId: process.env['NEXT_PUBLIC_ADMIN_GOOGLE_ANALYTICS_ID'] as string,
    googleUniversalAnalyticsId: process.env['NEXT_PUBLIC_ADMIN_GOOGLE_UNIVERSAL_ANALYTICS_ID'] as string,
    googleAdsId: process.env['NEXT_PUBLIC_ADMIN_GOOGLE_ADS_ID'] as string,
    googleApiClientId: process.env['NEXT_PUBLIC_ADMIN_GOOGLE_API_CLIENT_ID'] as string,
    facebookPixelId: process.env['NEXT_PUBLIC_ADMIN_FACEBOOK_PIXEL_ID'] as string,
    facebookAppId: process.env['NEXT_PUBLIC_ADMIN_FACEBOOK_APP_ID'] as string,
    twitterPixelId: process.env['NEXT_PUBLIC_ADMIN_TWITTER_PIXEL_ID'] as string,
    tikTokPixelId: process.env['NEXT_PUBLIC_ADMIN_TIKTOK_PIXEL_ID'] as string,
    redditPixelId: process.env['NEXT_PUBLIC_ADMIN_REDDIT_PIXEL_ID'] as string,
    klaviyoPublicApiKey: process.env['NEXT_PUBLIC_ADMIN_KLAVIYO_PUBLIC_API_KEY'] as string,
}

export interface Language
{
    /** Language code. Eg: en, hu, ro */
    code: string, 
    /** The name of the language in the given language. */
    name: string,
}

export interface AppLanguage extends Language
{
    /** A language local name. Eg: en_US */
    locale: string,
}

export default config;