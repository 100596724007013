import { FunctionComponent, lazy, Suspense } from 'react';
import { Col, FloatingLabel, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
// Lazy import the TinyMceEditor component
// @ts-ignore
const TinyMceEditor = lazy(() => import("@tinymce/tinymce-react").then(module => ({ default: module.Editor })));
import { formatDateTime } from '@xFrame4/common/Functions';
import CheckboxList, { CheckboxListLayout } from './CheckboxList';

export interface FieldEditorProps
{
    /** The type of the field editor. */
    type: FieldEditorType;
    /** The name of the field. */
    field: string;
    /** The label for the editor. */
    label?: string;
    /** The position of the label. */
    labelPosition?: FieldEditorLabelPosition;
    /** Is this field required? */
    isRequired?: boolean;
    /** Is this field visible? */
    isVisible?: boolean;
    /** Is the editing enabled? */
    isEnabled?: boolean;
    /** The placeholder for the field value. */
    placeholder?: string;
    /** CSS class for the control. */
    className?: string;
    /** The datasource for Select, List or listlike editors. */
    dataSource?: {[index: string]: any}[];
    /** The datasource object's property that is human readable. */
    dataSourceDisplayMember?: string;
    /** The datasource object's property that is used as the real value for the field. */
    dataSourceValueMember?: string;
    /** Allow multiple file upload for an File editor control (needs a FileList object). */
    /** Hide the the empty option in the select control. */
    hideSelectEmpty?: boolean;
    allowMultipleFileUpload?: boolean;
    /** The maximum length of the input text. */
    maxLength?: number;
    /** The minimum value of a number, range, date, datetime-local, month, time and week field. */
    min?: string | number;
    /** The maximum value of a number, range, date, datetime-local, month, time and week field. */
    max?: string | number;
    /** The number of rows in a text area. */
    textareaRows?: number;
    /** Insert an element before the input element. */
    beforeInput?: React.ReactNode
    /** Insert an element after the input element. */
    afterInput?: React.ReactNode
    /** The invalid feedback message. */
    invalidFeedbackMessage?: string;
    /** The reference to the file input element. Use this with useRef() to clear the file input value. (fileInputRef.current!.value = '') */
    fileInputRef?: any;
    /** The value of the field. */
    value?: any;
    /** Fired when the field value changes in the editor. In case of Select/List based on a generated value/display objects and you don't need the underlying generated object just use the value field: value.value */
    onValueChanged?: (field: string, value: any) => void;
    /** Fired when a key is pressed down */
    onKeyDown?: (key: string) => void;
}

const FieldEditor: FunctionComponent<FieldEditorProps> = (props) =>
{
    /** Default props */
    const labelPosition = props.labelPosition ?? FieldEditorLabelPosition.Vertical;
    const isRequired = props.isRequired ?? false;
    const isVisible = props.isVisible ?? true;
    const isEnabled = props.isEnabled ?? true;
    const dataSourceValueMember = props.dataSourceValueMember ?? 'value';
    const dataSourceDisplayMember = props.dataSourceDisplayMember ?? 'display';

    /** Parse the input value in correct format. */
    const parseInput = (value: any) => {
        if (props.type == FieldEditorType.Number && value != null && value != '' && !isNaN(value))
        {
            return parseFloat(value);
        }
        else if (props.type == FieldEditorType.DateTimeLocal && value != null && value != '')
        {
            return formatDateTime(new Date(value));
        }
        else 
        {
            return value;
        }
    };

    /** Is the input invalid? */
    let isInvalid = props.invalidFeedbackMessage !== undefined && props.invalidFeedbackMessage != null && props.invalidFeedbackMessage != '';
    
    /** Create the input control */
    let input: React.ReactNode = <></>;

    if ([
        FieldEditorType.Text, 
        FieldEditorType.Email, 
        FieldEditorType.Password, 
        FieldEditorType.Number, 
        FieldEditorType.Date,
        FieldEditorType.Time,
        FieldEditorType.DateTimeLocal,
        ].includes(props.type))
    {
        input = 
        <Form.Control 
            type={props.type} 
            name={props.field}
            value={props.value ?? ''} // https://stackoverflow.com/questions/37427508/react-changing-an-uncontrolled-input
            required={isRequired}
            isInvalid={isInvalid}
            disabled={!isEnabled}
            placeholder={props.placeholder} 
            aria-label={props.label}
            maxLength={props.maxLength}
            min={props.min}
            max={props.max}
            onChange={(event) => { if (props.onValueChanged) props.onValueChanged(props.field, parseInput(event.target.value)) }} 
            onKeyDown={(event) => { if (props.onKeyDown) props.onKeyDown(event.key) }}
            onWheel={(event) => (event.target as HTMLElement).blur()} // https://stackoverflow.com/questions/63224459/disable-scrolling-on-input-type-number-in-react
        />
    }
    else if (props.type == FieldEditorType.Select)
    {
        let options = props.dataSource?.map(item => 
            {
                let value = item[dataSourceValueMember as string];
                let display = getDataSourceDisplayMemberValue(item, dataSourceDisplayMember as string);

                return <option key={value} value={value}>{display}</option>
            }
        );

        let value;
        if (props.value !== null && props.value !== undefined)
        {
            // Accepts an object or a scalar
            value = typeof props.value == 'object' ? props.value[dataSourceValueMember!] : props.value;
        }
        
        input = 
        <Form.Select 
            value={value ?? ''} 
            name={props.field}
            required={isRequired}
            isInvalid={isInvalid}
            disabled={!isEnabled}
            aria-label={props.label} 
            onChange={(event) => {
                if (props.onValueChanged) 
                {
                    let item = props.dataSource?.find(item => {
                        return item[dataSourceValueMember!] == event.target.value // Find the selected item based on the selected value
                    });

                    if (item === undefined)
                    {
                        props.onValueChanged(props.field, { [dataSourceValueMember!]: null, [dataSourceDisplayMember!]: '' }); // return an item with null values and empty display
                    }
                    else
                    {
                        props.onValueChanged(props.field, item); // return the selected item
                    }
                }
            }}
        >
            {!(props.hideSelectEmpty ?? false) && <option value=""></option>}
            {options}
        </Form.Select>
    }
    else if (props.type == FieldEditorType.List)
    {
        input = 
        <CheckboxList 
            dataSource={props.dataSource!} 
            dataSourceDisplayMember={dataSourceDisplayMember}
            dataSourceValueMember={dataSourceValueMember}
            disabled={!isEnabled}
            layout={labelPosition == FieldEditorLabelPosition.Floating || labelPosition == FieldEditorLabelPosition.Horizontal ? CheckboxListLayout.Horizontal : CheckboxListLayout.Vertical}
            value={props.value ?? []}
            onChange={(selectedItems) => {if (props.onValueChanged) props.onValueChanged(props.field, selectedItems)}} 
        />
    }
    else if (props.type == FieldEditorType.Textarea)
    {
        input = 
        <Form.Control 
            as="textarea" 
            name={props.field}
            value={props.value ?? ''}
            rows={props.textareaRows ?? 6}
            required={isRequired}
            isInvalid={isInvalid}
            disabled={!isEnabled}
            placeholder={props.placeholder}
            aria-label={props.label}
            onChange={(event) => {if (props.onValueChanged) props.onValueChanged(props.field, event.target.value)}} 
        />
    }
    else if (props.type == FieldEditorType.RichTextEditor)
    {
        input = 
        <Suspense fallback={<div>Loading Editor...</div>}>
            <TinyMceEditor
                tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.0.1/tinymce.min.js"
                value={props.value ?? ''}
                init={{
                    height: (props.textareaRows!) * 60,
                    placeholder: props.placeholder,
                    menubar: true,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
                // @ts-ignore
                onEditorChange={(content, editor) => {if (props.onValueChanged) props.onValueChanged(props.field, content)}}
            />
        </Suspense>
    }
    else if (props.type == FieldEditorType.Checkbox)
    {
        input = 
        <Form.Check 
            type="checkbox"
            checked={props.value ? true : false}
            name={props.field}
            label={props.label}
            required={isRequired}
            feedback={props.invalidFeedbackMessage}
            feedbackType="invalid"
            isInvalid={isInvalid}
            disabled={!isEnabled}
            aria-label={props.label}
            className={isInvalid ? '' : 'd-flex align-items-start'}
            onChange={(event) => {if (props.onValueChanged) props.onValueChanged(props.field, event.target.checked)}} 
        />
    }
    else if (props.type == FieldEditorType.File)
    {
        input = 
        <Form.Control 
            type="file"
            name={props.field}
            multiple={props.allowMultipleFileUpload ?? false}
            ref={props.fileInputRef}
            required={isRequired}
            isInvalid={isInvalid}
            disabled={!isEnabled}
            placeholder={props.placeholder} 
            aria-label={props.label}
            onChange={(event) => fileChangeHandler(event)} 
        />
    }

    /** Selected file changed. */
    const fileChangeHandler = (event: any) => 
    {
        if (event.target.files != null && event.target.files.length > 0)
        {
            if (props.onValueChanged !== undefined)
            {
                props.onValueChanged(props.field, !props.allowMultipleFileUpload ? event.target.files[0] : event.target.files);
            }
        }
    };

    /** Add label */
    let cmpInputWithLabel = <></>;
    let controlId = 'field-editor-' + props.field;
    switch (labelPosition)
    {
        case FieldEditorLabelPosition.Vertical:
            {
                cmpInputWithLabel = 
                <Form.Group 
                    controlId={controlId}
                >
                    {props.type != FieldEditorType.Checkbox && <Form.Label>{props.label}</Form.Label>}

                    {props.beforeInput}
                    {input}
                    {props.afterInput}

                    {props.type != FieldEditorType.Checkbox &&
                        <div className="invalid-feedback">
                            {props.invalidFeedbackMessage}
                        </div>
                    }
                </Form.Group>
            }
            break;
        case FieldEditorLabelPosition.Horizontal:
            {
                cmpInputWithLabel = 
                <Form.Group 
                    as={Row} 
                    className="align-items-center"
                    controlId={controlId}
                >
                    {props.type != FieldEditorType.List && 
                    <Form.Label column sm={4}>
                        {props.type != FieldEditorType.Checkbox && <Form.Label>{props.label}</Form.Label>}
                    </Form.Label>
                    }
                    <Col sm={props.type != FieldEditorType.List ? 8 : 12}>
                        {props.beforeInput}
                        {input}
                        {props.afterInput}
                    </Col>
                </Form.Group>
            }
            break;
        case FieldEditorLabelPosition.Floating:
            {
                cmpInputWithLabel = 
                <FloatingLabel 
                    controlId={controlId}
                    label={props.type != FieldEditorType.Checkbox && props.label}
                >
                    {props.beforeInput}
                    {input}
                    {props.afterInput}
                </FloatingLabel>
            }
            break;
        case FieldEditorLabelPosition.Hidden:
            {
                cmpInputWithLabel = 
                <Form.Group 
                    controlId={controlId}
                >
                    {props.beforeInput}
                    {input}
                    {props.afterInput}
                </Form.Group>
            }
            break;
    }

    /** Render */
    return (
        <div className={`field-editor field-editor-${props.type} ${props.className ?? ''}`} style={{display: isVisible ? 'block' : 'none'}}>
            {cmpInputWithLabel}
        </div>
    );
}

export enum FieldEditorType
{
    Text = 'text',
    Email = 'email',
    Password = 'password',
    Number = 'number',
    /** Accepts a list of objects. Single selection. */
    Select = 'select',
    /** Accepts a list of objects. Multiple selection. */
    List = 'list',
    Date = 'date',
    Time = 'time',
    DateTimeLocal = 'datetime-local',
    Textarea = 'textarea',
    RichTextEditor = 'rich-text-editor',
    Checkbox = 'checkbox',
    File = 'file',
}

export enum FieldEditorLabelPosition
{
    Vertical,
    Horizontal,
    Floating,
    Hidden
}

/**
 * Converts an array of strings to a datasource array.
 * 
 * @param stringArray 
 */
export function stringArrayToDataSource(stringArray: string[])
{
    let dataSource: any[] = [];

    for (let str of stringArray)
    {
        let dataSourceRow = {
            value: str,
            display: str
        };
        dataSource.push(dataSourceRow);
    }

    return dataSource;
}

/**
 * Converts an array of numbers to a datasource array.
 */
export function numberArrayToDataSource(numberArray: number[])
{
    let dataSource: any[] = [];

    for (let num of numberArray)
    {
        let dataSourceRow = {
            value: num,
            display: num
        };
        dataSource.push(dataSourceRow);
    }

    return dataSource;
}

/**
 * Converts an enum to a datasource array. 
 * When using with a FieldEditor component, you will have to use value.value to get the real value. Eg: updateEntityField(field, value.value)}
 * 
 * @param enumObject The object that needs to be converted.
 * @param t A function to translate the key for display.
 * @param translationPrefix The prefix for the translation key. Eg: 'ORDER_STATUS_' + key
 */
export function enumToDataSource(enumObject: any, t?: (text: string) => string, translationPrefix?: string)
{
    let dataSource: any[] = [];

    for (let [key, value] of Object.entries(enumObject))
    {
        let dataSourceRow = {
            value: value,
            display: t ? t(translationPrefix ? translationPrefix + key : key) : key
        };
        dataSource.push(dataSourceRow);
    }

    return dataSource;
}

/**
 * Get the value for the dataSourceDisplayMember.
 * 
 * @param item The datasource item (row).
 * @param dataSourceDisplayMember The display member.
 * @returns 
 */
export function getDataSourceDisplayMemberValue(item: any, dataSourceDisplayMember: string)
{
    let displayValue: any;

    if (!dataSourceDisplayMember.includes('.'))
    {// Simple member
        displayValue = item[dataSourceDisplayMember];
    }
    else
    {// Complex member. Eg: actor.person.lastName
        let memberObject = item;

        let fieldSplits = dataSourceDisplayMember.split('.');
        for (let fieldSplit of fieldSplits)
        {
            memberObject = memberObject[fieldSplit];
        }

        if (memberObject !== undefined) displayValue = memberObject;
    }

    return displayValue;
}

export default FieldEditor;