import BlogPostTagGenerated, { BlogPostTagGeneratedEntityManager } from './generated/BlogPostTag.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class BlogPostTagEntityManager extends BlogPostTagGeneratedEntityManager<BlogPostTag>
{
    constructor()
    {
        super({
            createEntity: () => new BlogPostTag(),
        });
    }
}

export class BlogPostTag extends BlogPostTagGenerated
{
    static _manager: BlogPostTagEntityManager;
    static get manager(): BlogPostTagEntityManager
    {
        if (!this._manager) this._manager = new BlogPostTagEntityManager();
        return this._manager;
    }
}

export default BlogPostTag;