import React from 'react';
import ReactDom from 'react-dom/client';
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { store } from '@redux/store';
import { Provider } from 'react-redux';
import App from './App';
import config from '@config';
import LoginView from '@xFrame4/components/admin/auth/LoginView';
import RegistrationView from '@xFrame4/components/admin/auth/RegistrationView';
import MyAccountView from '@xFrame4/components/admin/auth/MyAccountView';
import DashboardView from '@components/dashboard/DashboardView';
import AppSettingAddEditForm from '@components/settings/AppSettingsAddEditForm';
import UserView from '@xFrame4/components/admin/auth/UserView';
import GroupView from '@xFrame4/components/admin/auth/GroupView';
import BlogPostCategoryView from '@components/blog/BlogPostCategoryView';
import BlogPostView from '@components/blog/BlogPostView';

run();

function run()
{
    const rootElement = document.getElementById('root');
    
    if (rootElement != null)
    {
        /**
         * React-Router remarks:
         * my-account, farm etc. can be nested inside the "/" route because the element <App> has an Outlet in one of its child elements
         * add, edit cannot be nested inside "asset-types", because  AssetTypeView has not Outlet for it
         */
        const root = ReactDom.createRoot(rootElement);
        root.render(
            <Provider store={store}>
                <BrowserRouter basename={config.reactRouterBaseName}>
                    <Routes>
                        <Route path="/" element={<App />}>
                            <Route index={true} element={<DashboardView />} />

                            <Route path="blog" element={<BlogPostView />} />
                            <Route path="blog-categories" element={<BlogPostCategoryView />} />
                            <Route path="my-account" element={<MyAccountView />} />
                            <Route path="users" element={<UserView />} />
                            <Route path="groups" element={<GroupView />} />

                            <Route path="settings" element={<AppSettingAddEditForm />} />
                        </Route>

                        <Route path="/login" element={<LoginView />} />
                        <Route path="/register" element={<RegistrationView />} />
                    </Routes>
                </BrowserRouter>
            </Provider>,
        );
    }
}