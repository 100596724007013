import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';



export class BlogPostCategoryGeneratedEntityManager<B extends BlogPostCategoryGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'BlogPostCategory',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'blogPostCategories',
        });
    }

}
export class BlogPostCategoryGenerated extends BusinessEntity
{
	/** The name of the blog category */
	name!: string;

}

export default BlogPostCategoryGenerated;