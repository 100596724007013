import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import FieldEditor, { enumToDataSource, FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import BlogPost from '@business/blog/BlogPost';
import BlogPostCategory from '@business/blog/BlogPostCategory';
import { BlogPostStatus } from '@business/blog/generated/BlogPost.generated';

interface BlogPostAddEditFormProps extends EntityAddEditFormProps<BlogPost> { }

const BlogPostAddEditForm: FunctionComponent<BlogPostAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<BlogPost>(props.entity ?? new BlogPost());
    const [categories, setCategories] = useState<BlogPostCategory[]>([]);

    /** Load BlogPostCategory foreign key data */
    useLoad(async () =>
    {
        const categoryResult = await BlogPostCategory.manager.load();
        if (categoryResult != null)
        {
            setCategories(categoryResult.entities);
        }
    });

    /** Tab: general */
    const cmpTabGeneral = (
        <>
            {/* Row 1: title and category_id */}
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="title"
                        label={t('TITLE')}
                        isRequired={true}
                        value={entity?.title}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="category"
                        label={t('CATEGORY')}
                        isRequired={true}
                        value={entity?.category}
                        dataSource={categories}
                        dataSourceDisplayMember="name"
                        dataSourceValueMember="id"
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            {/* Row 2: slug and status */}
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="slug"
                        label={t('SLUG')}
                        value={entity?.slug}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="status"
                        label={t('STATUS')}
                        isRequired={true}
                        value={entity?.status}
                        dataSource={enumToDataSource(BlogPostStatus, t)}
                        onValueChanged={(field, value) => updateEntityField(field, value.value)}
                    />
                </Col>
            </Row>
            {/* Row 3: content */}
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Textarea}
                        field="content"
                        label={t('CONTENT')}
                        value={entity?.content}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            {/* Row 4: excerpt */}
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Textarea}
                        field="excerpt"
                        label={t('EXCERPT')}
                        value={entity?.excerpt}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            {/* Row 5: tags */}
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="tags"
                        label={t('TAGS')}
                        value={entity?.tags}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
    );

    return (
        <EntityAddEditForm<BlogPost>
            entity={entity}
            mode={mode}
            titleAdd={t('ADD') + ' ' + t('BLOG_POST').toLowerCase()}
            titleEdit={t('EDIT') + ': ' + entity?.title}
            onClose={() => props.afterEdit?.(mode, entity)}
        >
            <TabContainer
                tabs={[
                    {
                        eventKey: 'general',
                        title: t('ADD_EDIT_TAB_GENERAL'),
                        content: cmpTabGeneral,
                    }
                ]}
            />
        </EntityAddEditForm>
    );
};

export default BlogPostAddEditForm;