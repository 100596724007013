import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import BlogPostCategory from '@business/blog/BlogPostCategory';

interface BlogPostCategoryAddEditFormProps extends EntityAddEditFormProps<BlogPostCategory> {}

const BlogPostCategoryAddEditForm: React.FC<BlogPostCategoryAddEditFormProps> = (props) => {
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<BlogPostCategory>(
        props.entity ?? new BlogPostCategory()
    );

    // Tab: general
    const cmpTabGeneral = (
        <>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="name"
                        label={t('NAME')}
                        isRequired={true}
                        value={entity?.name}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
    );

    return (
        <EntityAddEditForm<BlogPostCategory>
            entity={entity}
            mode={mode}
            titleAdd={t('ADD') + ' ' + t('BLOG_POST_CATEGORY').toLowerCase()}
            titleEdit={t('EDIT') + ': ' + entity?.name}
            onClose={() => props.afterEdit?.(mode, entity)}
        >
            <TabContainer
                tabs={[
                    {
                        eventKey: 'general',
                        title: t('ADD_EDIT_TAB_GENERAL'),
                        content: cmpTabGeneral,
                    }
                ]}
            />
        </EntityAddEditForm>
    );
};

export default BlogPostCategoryAddEditForm;