import BlogPostCategoryGenerated, { BlogPostCategoryGeneratedEntityManager } from './generated/BlogPostCategory.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class BlogPostCategoryEntityManager extends BlogPostCategoryGeneratedEntityManager<BlogPostCategory>
{
    constructor()
    {
        super({
            createEntity: () => new BlogPostCategory(),
        });
    }
}

export class BlogPostCategory extends BlogPostCategoryGenerated
{
    static _manager: BlogPostCategoryEntityManager;
    static get manager(): BlogPostCategoryEntityManager
    {
        if (!this._manager) this._manager = new BlogPostCategoryEntityManager();
        return this._manager;
    }
}

export default BlogPostCategory;