import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';



export class BlogPostTagGeneratedEntityManager<B extends BlogPostTagGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'BlogPostTag',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'slug', type: BusinessEntityFieldType.VarChar },
				{ name: 'description', type: BusinessEntityFieldType.Text },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'blogPostTags',
        });
    }

}
export class BlogPostTagGenerated extends BusinessEntity
{
	/** The name of the tag */
	name!: string;
	/** The URL-friendly version of the tag name */
	slug!: string;
	/** Optional description of the tag */
	description: string = '';

}

export default BlogPostTagGenerated;