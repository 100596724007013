import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import BlogPostCategory from '@business/blog/BlogPostCategory';
import BlogPostTag from '@business/blog/BlogPostTag';

export enum BlogPostStatus
{
    PUBLISHED = 'PUBLISHED',
    DRAFT = 'DRAFT',

}

export class BlogPostGeneratedEntityManager<B extends BlogPostGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'BlogPost',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'category', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'categoryId', inputProperty: 'category.id', relatedManager: BlogPostCategory.manager },
				{ name: 'status', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'dateAdded', type: BusinessEntityFieldType.DateTime, isInput: false },
				{ name: 'title', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'slug', type: BusinessEntityFieldType.VarChar },
				{ name: 'content', type: BusinessEntityFieldType.Text },
				{ name: 'excerpt', type: BusinessEntityFieldType.Text },
				{ name: 'seoTitle', type: BusinessEntityFieldType.VarChar },
				{ name: 'seoDescription', type: BusinessEntityFieldType.Text },
				{ name: 'seoKeywords', type: BusinessEntityFieldType.Text },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'blogPosts',
        });
    }

}
export class BlogPostGenerated extends BusinessEntity
{
	/** The category of the blog post */
	category!: BlogPostCategory;
	/** The status of the blog post */
	status: BlogPostStatus = BlogPostStatus.DRAFT;
	/** The date the blog post was added */
	dateAdded!: string;
	/** The title of the blog post */
	title!: string;
	/** The slug for the blog post */
	slug!: string;
	/** The content of the blog post */
	content: string = '';
	/** The excerpt of the blog post */
	excerpt: string = '';
	/** The SEO title of the blog post */
	seoTitle: string = '';
	/** The SEO description of the blog post */
	seoDescription: string = '';
	/** The SEO keywords for the blog post */
	seoKeywords: string = '';

	tags: ManyToManyCollection<BlogPostTag> = new ManyToManyCollection<BlogPostTag>(this, 'business/blog/BlogPost', 'tags', 'business/blog/BlogPostTag', BlogPostTag.manager, 'post');
}

export default BlogPostGenerated;