import React from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@xFrame4/components/admin/EntityView';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@xFrame4/components/admin/EntityViewHooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import BlogPost from '@business/blog/BlogPost';
import BlogPostAddEditForm from './BlogPostAddEditForm';

interface BlogPostViewProps {}

const BlogPostView: FunctionComponent<BlogPostViewProps> = (props) => {
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<BlogPost>(BlogPost);

    // Define grid columns based on the view configuration.
    const columns = React.useMemo<GridColumns<BlogPost>>(
        () => [
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'dateAdded', headerName: t('DATE_ADDED'), type: 'dateTime', flex: 1 },
            { field: 'title', headerName: t('TITLE'), type: 'string', flex: 1 },
            { field: 'status', headerName: t('STATUS'), type: 'string', flex: 1 },
            {
                field: 'category',
                headerName: t('CATEGORY'),
                type: 'string',
                flex: 1,
                valueGetter: (params) => params.row.category?.name,
            },
        ],
        [t],
    );

    return (
        <>
            {addEditEntity ? (
                <BlogPostAddEditForm
                    entity={addEditEntity}
                    afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
                />
            ) : (
                <EntityView<BlogPost>
                    id="blogPosts"
                    title={t('BLOG_POSTS')}
                    manager={BlogPost.manager}
                    baseFilter={{}}
                    pageSize={15}
                    buttonsLeft={[
                        {
                            text: t('ADD'),
                            variant: 'danger',
                            onClick: () => onAddEditOpen(AddEditFormMode.Add),
                        },
                    ]}
                    gridInfo={{
                        columns: columns,
                        onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity),
                    }}
                    filterEditors={[
                        { field: 'title_Icontains', label: t('TITLE'), type: FieldEditorType.Text },
                        { field: 'content_Icontains', label: t('CONTENT'), type: FieldEditorType.Text },
                    ]}
                    actions={[]}
                    deleteConfirmDialogInfo={{
                        title: (entity) => t('DELETE') + ' ' + entity.title + '?',
                        onConfirmed: (entity) => entity.delete(),
                    }}
                    addedEntity={addedEntity}
                    editedEntity={editedEntity}
                />
            )}
        </>
    );
};

export default BlogPostView;